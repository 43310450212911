import { createTheme, ThemeProvider, alpha } from "@mui/material";
import { createContext, useMemo, useState } from "react";

export const ColorModeContext = createContext({
    toggleMode: () => { },
    mode: "dark",
});

const scrollbarStyles = `
*::-webkit-scrollbar {
  width: 0.4em;
  height: 0.6em;
}
*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}
*::-webkit-scrollbar-thumb {
  background-color: #C8C8C8;
  border-radius: 8px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
`;

export const ColorContextProvider = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    const lightTheme = createTheme({
        palette: {
            mode: "light",
            primary: {
                main: "#333333",
            },
            secondary: {
                main: "#f50057",
            },
            background: {
                default: "#fff",
                paper: "#ffffff",
                pageContent: "#f9f9f9",
                headerplaningitem: "#fff",
                topPopupmaintheme: "#e7e7e7",
                performanceBox: "#f5f4f6",
                datePicker: "rgb(255,255,255)",
                headerColor: "#fff",
                menuCalender: "#fff",
                reportDownload: "transparent",
                rowSelected: alpha('#82cafa', 0.5),
                menuColour: 'white',
                detailPage: '#f9f9f9',
            },
            textcolors: {
                headerplaningitemtext: "#000",
                ttt: "#000",
                headerdatetimeboxright: "#8a8fa0",
                lastupdatetime: "#8a8fa0",
                modelfilterbtn: "#fff",
                totalPercUp: "#608b70",
                totalPercDown: "#a8605a",
                pageheading: "#333333",
                spanclr: "#3e73ff",
                spanclr2: "#19a33d",
                sidebarText: "#767676",
            },
            borderClr: {
                homeCartborder: "1px solid #f0f0f0",
                reportDownload: "1px solid #f5f4f6",
            },
            roi: {
                positiveBackground: alpha('#4caf50', 0.20),
                negativeBackground: alpha('#f44336', 0.20),
                thirtyPlusPositiveBackground: alpha('#ff6600', 0.20),
                positiveText: '#1b5e20',
                negativeText: '#b71c1c'
            },
        },
        customShadows: {
            headerplaningitem: "0 0 13px #ccc",
            menuCalender: "0 0 1rem 0 #dfdfdf",
        },
        customBorderColor: {
            borderColor: "rgba(0, 0, 0, 0.1)",
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: scrollbarStyles,
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        borderRadius: '12px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'rgba(0, 0, 0, 0.23)',
                            },
                            '&:hover fieldset': {
                                borderColor: 'rgba(0, 0, 0, 0.5)',
                            },

                        },
                    },
                },
            },
        },
    });

    const darkTheme = createTheme({
        palette: {
            mode: "dark",
            primary: {
                main: "#426ee5",
            },
            secondary: {
                main: "#03dac6",
            },
            background: {
                default: "#131517",
                paper: "#1e1f25",
                pageContent: "#000",
                headerplaningitem: "#1e1f25",
                topPopupmaintheme: "#1e1f25",
                performanceBox: "#1e1f25",
                datePicker: "#1e1f25",
                headerColor: "#131517",
                menuCalender: "none",
                reportDownload: "#1e1f25",
                rowSelected: '#343f5f',
                menuColour: '#1a1a1a',
                detailPage: 'black',
            },
            textcolors: {
                headerplaningitemtext: "#fff",
                ttt: "#fff",
                sidebarText: "#fff",
                headerdatetimeboxright: "#8a8fa0",
                lastupdatetime: "#fff",
                modelfilterbtn: "#fff",
                totalPercUp: "#fff",
                totalPercDown: "#fff",
                pageheading: "#fff",
                spanclr: "#3e73ff",
                spanclr2: "#19a33d",
            },
            borderClr: {
                homeCartborder: "none",
                reportDownload: "none",
            },
            roi: {
                positiveBackground: alpha('#4caf50', 0.25),
                negativeBackground: alpha('#f44336', 0.25),
                thirtyPlusPositiveBackground: alpha('#ff6600', 0.20),
                positiveText: '#66bb6a',
                negativeText: '#ef5350',
            },
        },
        customShadows: {
            headerplaningitem: "none",
            menuCalender: "none",
        },
        customBorderColor: {
            borderColor: "#fff",
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: scrollbarStyles,
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        borderColor: 'rgba(255, 255, 255, 0.12)',
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '12px',
                            '& fieldset': {
                                borderColor: '#ffffff',
                            },

                        },
                    },
                },
            },
        },
    });

    const theme = useMemo(
        () => (isDarkMode ? darkTheme : lightTheme),
        [darkTheme, isDarkMode, lightTheme]
    );

    return (
        <ColorModeContext.Provider value={{ isDarkMode, setIsDarkMode, theme }}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ColorModeContext.Provider>
    );
};
