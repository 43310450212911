import { lazy } from "react";

const LoginPage = lazy(() => import("../pages/Login"));
const RegisterPage = lazy(() => import("../pages/Signup"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Pnl = lazy(() => import("../pages/Pnl"));
const Trends = lazy(() => import("../pages/Trends"));
const CreateUser = lazy(() => import("../components/users/CreateUser"));
const UserDetail = lazy(() => import("../components/users/UserDetails"));
const UpdateUser = lazy(() => import("../components/users/UpdateUser"));
const User = lazy(() => import("../pages/Users"));
const AdAccountList = lazy(() => import("../pages/AdAccountList"));
const CPAList = lazy(() => import("../pages/CPA"));
const Profile = lazy(() => import("../pages/Profile"));
const Status = lazy(() => import("../pages/Status"));
const TargetCampaignList = lazy(() => import("../components/cpa/TargetCampaignList"));
const PnlAds = lazy(() => import("../pages/PnlAds"));
const PnlAdsByCreative = lazy(() => import("../pages/PnlAdsByCreative"));

export const publicRoutes = [
  { path: "/", component: LoginPage },
  { path: "/signup", component: RegisterPage },
  { path: "/forgot-password", component: ForgotPassword },
];

export const privateRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/pnl", component: Pnl },
  { path: "/trends", component: Trends },
  { path: "/users", component: User },
  { path: "/create-user", component: CreateUser },
  { path: "/update-user", component: UpdateUser },
  { path: "/user-detail", component: UserDetail },
  { path: "/ad-accounts", component: AdAccountList },
  { path: "/cpa", component: CPAList },
  { path: "/target-campaign", component: TargetCampaignList },
  { path: "/profile", component: Profile },
  { path: "/pnl-ads", component: PnlAds },
  { path: "/pnl-creative", component: PnlAdsByCreative },
  { path: "/status", component: Status },
];
