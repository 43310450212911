export const API_ENDPOINTS = {
  AUTH: {
    LOGIN: "/auth/login",
    SIGNUP: "/auth/signup",
    RESET_PASSWORD: "/auth/reset-password",
    VERIFY_EMAIL: "/auth/verify-email",
  },
  USER: {
    GET_ALL: "/user",
    GET_ONE: (id) => `/user/${id}`,
    CREATE: "/user",
    UPDATE: (id) => `/user/${id}`,
    UPDATE_MEDIA_BUYERS: (id) => `/user/${id}/media-buyers`,
    GET_MEDIA_BUYERS: (id) => `/user/${id}/media-buyers`,
  },
  CAMPAIGN: {
    GET_PNLS: "/campaign/pnls",
    GET_TRENDS: "/campaign/trends",
    GET_UNIQUE_VERTICALS: "/campaign/filters/verticals",
    GET_UNIQUE_TRAFFIC_PLATFORMS: "/campaign/filters/traffic-platforms",
    GET_UNIQUE_TRAFFIC_PLATFORM_SOURCES:
      "/campaign/filters/traffic-platform-sources",
    GET_UNIQUE_AGENCIES: "/campaign/filters/agencies",
    GET_UNIQUE_MEDIA_BUYERS: "/campaign/filters/media-buyers",
    GET_METRICS: "/campaign/metrics",
    GET_HOURLY_TRENDS: "/dashboard/hourly-trends",
    GET_MEDIA_BUYERS: "/campaign/media-buyers",
    GET_VERTICALS: "/campaign/verticals",
    UPDATE_CAMPAIGN_BUDGET: "/campaign/campaign-budget",
    UPLOAD_CAMPAIGN_CSV: "/campaign/csv-upload",
    GET_PNL_ADS: "/campaign/pnl-ads",
    GET_CREATIVE_PNL: "/campaign/pnl-ads-by-creative",
    UPDATE_CAMPAIGNS_MANUALLY: "/campaign/update-campaigns-manually",
    GET_CREATIVE_STATE_WISE_DATA: "/campaign/state-wise-breakdown",
    UPDATE_CAMPAIGNS_CONFIG: "/campaign/update-campaigns-config",
    GET_CREATIVE_RANKINGS: "/campaign/creative-details",
    GET_CREATIVE_COMPARISON: "/campaign/compare-creatives",
    GET_CREATIVE_SAUCE_VIEW: "/campaign/secret-sauce",
  },
  AD_ACCOUNT: {
    ASSIGN_VERTICAL: (accountId, verticalId) =>
      `/accounts/${accountId}/assign-vertical/${verticalId}`,
    ASSIGN_MEDIA_BUYER: (accountId, mediaBuyerId) =>
      `/accounts/${accountId}/assign-mb/${mediaBuyerId}`,
    GET_AD_ACCOUNT: `/accounts`,
  },
  RINGBA: {
    GET_CALL_STATUS: "/ringba/ringba-calls-status",
    GET_TARGET_CAPS: "/ringba/target-caps",
    GET_TARGET_CAMPAIGNS: "/ringba/filter/multi-select-buyer",
  },
  PREFERENCES: {
    GET_PREFERENCES: "/preferences",
    UPDATE_PREFERENCES: "/preferences"
  },
  API_STATUS: {
    GET_API_STATUS_METRICS: "/api-status"
  },
  TWO_FA :{
    SETUP :"/2fa/setup",
    VERIFY:"/2fa/verify"
  }
};
