import React, { useState } from 'react';
import { Button, Tooltip, useTheme, Box } from '@mui/material';
import { LocalizationProvider, StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import { useDateRange } from '../../context/DateRangeContext';
import CalendarIcon from '../../asset/image/calendar.svg'
import GradientButton from '../common/GradientButton';

moment.tz.setDefault("America/New_York");

const shortcutsItems = [
    {
        label: "Today",
        getValue: () => {
            const today = moment();
            return [today, today];
        },
    },
    {
        label: "Yesterday",
        getValue: () => {
            const yesterday = moment().subtract(1, 'day');
            return [yesterday, yesterday];
        },
    },
    {
        label: "This Week",
        getValue: () => {
            const today = moment();
            return [today.clone().startOf("week"), today.clone().endOf("week")];
        },
    },
    {
        label: "Last 7 Days",
        getValue: () => {
            const today = moment();
            return [today.clone().subtract(6, "day"), today];
        },
    },
    {
        label: "Last Week",
        getValue: () => {
            const today = moment();
            const lastWeek = today.clone().subtract(1, "week");
            return [lastWeek.clone().startOf("week"), lastWeek.clone().endOf("week")];
        },
    },
    {
        label: "Last 14 Days",
        getValue: () => {
            const today = moment();
            return [today.clone().subtract(13, "day"), today];
        },
    },
    {
        label: "Last 30 Days",
        getValue: () => {
            const today = moment();
            return [today.clone().subtract(29, "day"), today];
        },
    },
    {
        label: "Current Month",
        getValue: () => {
            const today = moment();
            return [today.clone().startOf("month"), today.clone().endOf("month")];
        },
    },
    {
        label: "This Year",
        getValue: () => {
            const today = moment();
            return [today.clone().startOf("year"), today];
        },
    },
    { label: "Reset", getValue: () => [null, null] },
];
const DateRangePicker = () => {
    const theme = useTheme();
    const { dateRange, setDateRange } = useDateRange();
    const [isOpen, setIsOpen] = useState(false);
    const [tempDateRange, setTempDateRange] = useState(dateRange);

    const isTrendsPage = false;

    const formatDateRange = (range) => {
        if (range[0] && range[1]) {
            return `${range[0].format('MMM D, YYYY')} - ${range[1].format('MMM D, YYYY')}`;
        }
        return 'Select Date Range';
    };

    const findMatchingShortcut = (range) => {
        if (!range[0] || !range[1]) return '';

        for (const shortcut of shortcutsItems) {
            const [start, end] = shortcut.getValue();
            if (start && end &&
                range[0].isSame(start, 'day') &&
                range[1].isSame(end, 'day')) {
                return shortcut.label;
            }
        }
        return '';
    };

    const handleOpenPicker = () => {
        setIsOpen(true);
        setTempDateRange(dateRange);
    };

    const handleApply = () => {
        setDateRange(tempDateRange);
        setIsOpen(false);
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    const getButtonText = () => {
        const shortcutLabel = findMatchingShortcut(dateRange);
        return shortcutLabel || formatDateRange(dateRange);
    };

    const buttonText = isTrendsPage ? 'Today' : getButtonText();


    const handleDateChange = (newValue) => {
        const shortcutLabel = findMatchingShortcut(newValue);
        if (shortcutLabel) {
            // If it's a shortcut, apply immediately
            setDateRange(newValue);
            setIsOpen(false);
        } else {
            // If it's a manual selection, just update tempDateRange
            setTempDateRange(newValue);
        }
    };


    return (
        <div className="DownloadlastMontht">
            <Tooltip sx={{ color: theme.palette.textcolors.headerplaningitemtext }} title={isTrendsPage ? 'Today' : formatDateRange(dateRange)} arrow>
                <Button
                    variant="outlined"
                    size="large"
                    startIcon={<img src={CalendarIcon} alt="Filter" width={"35px"} />}
                    disabled={isTrendsPage}
                    onClick={handleOpenPicker}
                    sx={{
                        color: theme.palette.textcolors.headerplaningitemtext,
                        border: theme.palette.borderClr.reportDownload,
                        backgroundColor: theme.palette.background.reportDownload,
                    }}
                >
                    <span className='nowrap'>{buttonText.length > 30 ? `${buttonText.substring(0, 27)}...` : buttonText}</span>
                </Button>
            </Tooltip>
            {!isTrendsPage && isOpen && (
                <Box
                    sx={{
                        boxShadow: theme.customShadows.menuCalender,
                        backgroundColor: theme.palette.background.paper,
                    }}
                    className="menu_calander">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <StaticDateRangePicker
                            sx={{
                                color: theme.palette.textcolors.headerplaningitemtext,
                                bgcolor: theme.palette.background.datePicker,
                                borderRadius: "20px",
                                padding: "10px",
                                '& .MuiDivider-root': {
                                    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                                },
                            }}
                            value={tempDateRange}
                            onChange={handleDateChange}
                            slotProps={{
                                shortcuts: { items: shortcutsItems },
                            }}
                        />
                    </LocalizationProvider>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, p: 1 }}>
                        <Button onClick={handleCancel} variant="outlined">
                            Cancel
                        </Button>
                        <GradientButton onClick={handleApply}>
                            Apply
                        </GradientButton>
                    </Box>
                </Box>
            )}
        </div>
    );
};

export default DateRangePicker;