import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ children }) => {
  const authToken = localStorage.getItem("authToken");
  const twoFAVerified = localStorage.getItem("2faVerified");

  if (authToken && twoFAVerified) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

export default PublicRoute;
